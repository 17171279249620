<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('connect.signage.addeditscreen')">
    <CCol cols="12" lg="12" md="12">
      <CCard class="signage wizard mb-0 d-block">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="5" xl="5" class="pt-0 pb-0 text-left">              
              {{$t("signage.Edit_screen")}}
            </CCol>
            <CCol cols="7" xl="7" class="pt-0 pb-0 text-xl-right">
              <div class="mt-1 mt-xl-0 d-inline-block align-top">
                <CButton class="m-0" color="primary" @click="showSignageOverview()">
                  <i class="fas fa-chevron-left mr-1"/>
                  <span>{{$t('common.Back_to_overview')}}</span>
                </CButton>
              </div> 
            </CCol> 
          </CRow>
        </CCardHeader>
        <CCardBody class="w-100">
          <CRow v-if="!screenDataLoaded || !screenContentLoaded" class="h-100">
            <CCol cols="12" xl="12" sm="12" class="pt-0 pb-0">
              <div class="d-flex content-center h-100">
                <loadingSpinner mode="auto" :content="$t('signage.Loading_screen_details')"/>
              </div>
            </CCol>
          </CRow>
          <CRow v-else>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <CRow>
                <CCol cols="12" lg="12" class="pt-0">
                  <quick-edit type="text" mode="ignore" :buttonOkText="$t('Update')" :buttonCancelText="$t('cancel')" v-model="screen.screen_title" v-on:input="updateScreen()">
                    <h2>{{screen.screen_title ? screen.screen_title : $t('common.Click_to_set_title')}}<i class="icon edit fas fa-pen ml-1"/></h2>
                  </quick-edit>                  
                </CCol>            
                <CCol cols="12" lg="12" class="pt-0">
                  <quick-edit type="textarea" mode="ignore" :buttonOkText="$t('Update')" :buttonCancelText="$t('cancel')" v-model="screen.screen_description" v-on:input="updateScreen()">
                    <span>{{screen.screen_description ? screen.screen_description : $t('common.Click_to_set_description')}}<i class="icon edit fas fa-pen ml-1"/></span>
                  </quick-edit>
                </CCol>
              </CRow>
              <CRow>           
                <CCol cols="12" lg="12" class="pt-0">
                  <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="screen.is_active" size="is-small" @input="updateScreen();">{{ $t('is_active') }}</b-switch>            
                </CCol>            
              </CRow>               
              <CRow>                       
                <CCol cols="6" lg="6" class="pt-0 pb-0">
                  <CRow>
                    <CCol cols="12" lg="12" class="pt-0 pb-0">                  
                      <label><b>{{ $t('signage.Screen_content') }}</b></label>
                      <div v-if="screenContent.length > 0" class="screen_content">
                        <b-table ref="contentTable" class="data_table no_head" :data="screenContent" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition">
                          <template slot-scope="props">
                            <b-table-column field="name" label="">                                                          
                              <span v-if="props.row.signage_content_name" @click="screenContentDetails = props.row; countdown();">{{props.row.signage_content_name}}</span>
                              <span v-else-if="props.row.type.type_code === 'sw_group_posts'" @click="screenContentDetails = props.row; countdown();">{{props.row.type.type_name + ' ' + props.row.sw_group.sw_group_name}}</span>
                              <span v-else-if="props.row.type.type_code === 'single_image' || props.row.type.type_code === 'info_slide'" @click="screenContentDetails = props.row; countdown();">{{props.row.type.type_name}}</span>
                              <span v-else @click="screenContentDetails = props.row; countdown();">{{$t('common.Step') + ' ' + (props.row.content_order + 1)}}</span>                            
                            </b-table-column>
                            <b-table-column field="details">
                              <div v-if="screenContent.length > 1" class="d-flex justify-content-lg-end">
                                <CDropdown color="primary" toggler-text="" :caret="false" class="ml-2 table_actions_dropdown">
                                  <div slot="toggler-content">
                                    <span class="d-flex align-items-center">
                                      {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                                    </span>
                                  </div>

                                  <CDropdownItem>
                                    <div class="d-flex align-items-center" @click="screenContentDetails = props.row; countdown();">
                                      <div class="post_action_icon text-center mr-1">
                                        <i class="fas fa-chevron-right"/>
                                      </div>
                                      <div class="flex-grow-1">
                                        <span>{{$t('common.Show_details')}}</span>
                                      </div>
                                    </div>
                                  </CDropdownItem>
                                  <CDropdownItem>
                                    <div class="d-flex align-items-center" @click="removeScreenContent(props.row.signage_content_id_external)">
                                      <div class="post_action_icon text-center mr-1">
                                        <i class="fas fa-trash"/>
                                      </div>
                                      <div class="flex-grow-1">
                                        <span>{{$t('signage.Remove_content')}}</span>
                                      </div>
                                    </div>
                                  </CDropdownItem>
                                </CDropdown>
                              </div>
                              <div v-else class="d-flex justify-content-lg-end">
                                <CButton class="d-inline-block" 
                                          color="primary"
                                          @click="screenContentDetails = props.row; countdown();">
                                  <i class="fas fa-chevron-right"/>
                                </CButton>
                                <CButton class="m-0 d-inline-block" 
                                          color="primary" 
                                          @click="removeScreenContent(props.row.signage_content_id_external)">
                                  <i class="fas fa-trash"/>
                                </CButton>                               
                              </div>
                            </b-table-column>
                          </template>                
                        </b-table>
                      </div>
                    </CCol>
                  </CRow>                     
                  <CRow>
                    <CCol cols="12" lg="12" class="pb-0" v-bind:class="{'pt-0' : screenContent.length === 0}">
                      <CButton color="primary" @click="addScreenContent();">
                        <i class="fas fa-plus mr-1"/>
                        <span>{{ $t('common.Add_content') }}</span>
                      </CButton>
                    </CCol>
                  </CRow>                    
                </CCol>

                <CCol cols="6" lg="6" class="pt-0 pb-0">
                  <label><b>{{ $t('signage.Screen_content_details') }}</b></label>
                  <div v-if="screenContentDetails.signage_content_id_external !== null" class="screen_content_details">
                    <CRow>
                      <CCol cols="12" lg="12" class="pt-0">
                        <label>{{ $t('signage.Content_type') }}</label>
                        <multiselect
                          class="data_table"
                          v-model="screenContentDetails.type" 
                          :options="screenContentTypes" 
                          :multiple="false"
                          :hideSelected="true"                          
                          :placeholder="$t('common.Select_content_type')" 
                          :selectLabel="$t('common.Add_content_type')"
                          :disabled="screenContentDetails.signage_content_type_id !== null"
                          track-by="type_code" 
                          label="type_name"
                          @input="setContentType()">
                          <span slot="noResult">{{ $t('common.no_types_found') }}</span>
                        </multiselect>
                      </CCol>
                      <CCol cols="12" lg="12" class="pt-0">
                        <CInput type="text" :label="$t('signage.Content_name')" class="mb-0" v-model="screenContentDetails.signage_content_name"/>
                      </CCol>
                    </CRow>
                    <CRow v-if="screenContentDetails.type !== undefined && screenContentDetails.type.type_code == 'sw_group_posts'">
                      <CCol cols="12" lg="12" class="pt-0">
                        <label>{{ $t('signage.SW_group') }}</label>
                        <multiselect                        
                          class="data_table"
                          v-model="screenContentDetails.sw_group" 
                          :options="screenContentValues" 
                          :multiple="false"                
                          :placeholder="$t('common.Select_sw_group')" 
                          :selectLabel="$t('common.Add_sw_group')" 
                          :selectedLabel="$t('Added')"
                          :deselectLabel="$t('common.Remove_sw_group')"
                          track-by="sw_group_id_external" 
                          label="sw_group_name"
                          @search-change="asyncFindSwGroup">
                          <span slot="noResult">{{ $t('common.no_sw_groups_found') }}</span>
                          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                        </multiselect>
                      </CCol>
                      <!-- <CCol cols="12" lg="12" class="pt-0" style="display">
                        <CInput type="number" :label="$t('signage.Number_of_sw_posts')" min="2" max="100" class="mb-0" v-model="screenContentDetails.sw_group_post_amount"/>
                      </CCol> -->
                    </CRow>
                    <CRow v-if="screenContentDetails.type !== undefined && screenContentDetails.type.type_code == 'single_image'">
                      <CCol cols="12" lg="12" class="pt-0">
                        <div v-if="screenContentDetails.signage_content_variable !== null">
                          <label>{{ $t('signage.Image_on_screen') }}</label>
                          <div class="current_content_image">                                                      
                            <div class="content_image">
                              <img :src="apiBaseUrl + '/v1/common/cdn/file/image/connect-signage/' + screenContentDetails.signage_content_id_external + '/' + screenContentDetails.signage_content_variable + '/' + clientToken"/>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <label>{{$t('signage.Upload_image')}}</label>
                          <div v-if="screenContentDetails.new_content_image !== null" class="content_image_preview">
                            <div class="content_image">
                              <img :src="screenContentDetails.new_content_image.image_url">
                              <i class="fas fa-times" @click="removeContentImage()"/>
                            </div>
                          </div>
                          <div v-else>                           
                            <input id="imageUpload" type="file" accept="image/*" :value="screenContentDetails.uploaded_content_image" @change="onContentImageChange" hidden>
                            <CButton color="secondary" @click="pickContentImage();"><i class="fas fa-image mr-1"/>{{$t('signage.Upload_image')}}</CButton>
                          </div>                                
                        </div>
                      </CCol>
                    </CRow>
                    <CRow v-if="screenContentDetails.type !== undefined && screenContentDetails.type.type_code == 'info_slide'">
                      <CCol cols="12" lg="12" class="pt-0" v-bind:class="{'pb-0' : screenContentDetails.signage_content_type_id !== null && screenContentDetails.signage_content_variable_2 === null}">
                        <label>{{ $t('signage.Info_slide_text') }}</label>
                        <div class="wysiwyg mb-2">
                          <ckeditor @input="countdown()" :editor="editor" v-model="screenContentDetails.info_slide_text" :config="editorConfig" :placeholder="$t('signage.Your_info_slide_text')"></ckeditor>
                          <p class="countdown mt-1 mb-0" v-if="remainingCount > 0">{{remainingCount}} {{ $t('points.characters_remaining') }}</p>
                          <p class="countdown warning mt-1 mb-0" v-if="remainingCount < 0">{{screenContentDetails.info_slide_text.length - maxCount}} {{ $t('points.characters_too_much') }}</p>                    
                        </div>
                        <div v-if="screenContentDetails.signage_content_type_id === null">
                          <label>{{$t('signage.Upload_image')}}</label>
                          <div v-if="screenContentDetails.new_content_image !== null" class="content_image_preview">
                            <div class="content_image">
                              <img :src="screenContentDetails.new_content_image.image_url">
                              <i class="fas fa-times" @click="removeContentImage()"/>
                            </div>
                          </div>
                          <div v-else>                           
                            <input id="imageUpload" type="file" accept="image/*" :value="screenContentDetails.uploaded_content_image" @change="onContentImageChange" hidden>
                            <CButton color="secondary" @click="pickContentImage();"><i class="fas fa-image mr-1"/>{{$t('signage.Upload_image')}}</CButton>
                          </div>                                
                        </div> 
                        <div v-else-if="screenContentDetails.signage_content_type_id !== null && screenContentDetails.signage_content_variable_2 !== null">
                          <label>{{ $t('signage.Info_slide_image') }}</label>
                          <div class="current_content_image">
                            <div class="content_image">
                              <img :src="apiBaseUrl + '/v1/common/cdn/file/image/connect-signage/' + screenContentDetails.signage_content_id_external + '/' + screenContentDetails.signage_content_variable_2 + '/' + clientToken"/>
                            </div>
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                    <CRow v-if="screenContentDetails.type !== undefined && screenContentDetails.type.type_code == 'birthdays'">                      
                      <CCol cols="12" lg="12" class="pt-0">
                        <CInput type="text" :label="$t('signage.Birthday_message')" class="mb-0" v-model="screenContentDetails.birthday_message"/>
                      </CCol>                      
                      <CCol cols="12" lg="12" class="pt-0">
                        <label>{{ $t('signage.Birthday_type') }}</label>
                        <multiselect class="data_table"
                                     v-model="screenContentDetails.birthday_type" 
                                     :options="birthdayTypes" 
                                     :multiple="false"
                                     :hide-selected="true"
                                     :placeholder="$t('signage.Select_birthday_type')" 
                                     :selectLabel="$t('signage.Add_birthday_type')" 
                                     track-by="tag" 
                                     label="label"
                                     @search-change="asyncFindSwGroup">
                          <span slot="noResult">{{ $t('signage.No_birthday_types_found') }}</span>
                          <span slot="noOptions">{{ $t('signage.start_typing_to_search') }}</span>
                        </multiselect>
                      </CCol>
                      <CCol v-if="screenContentDetails.birthday_type" cols="12" lg="12" class="pt-0" style="display">
                        <multiselect v-if="screenContentDetails.birthday_type.tag === 'work_location'"
                                     class="data_table"
                                     v-model="screenContentDetails.birthdays_from_work_location" 
                                     :options="screenContentValues" 
                                     :multiple="false"                
                                     :placeholder="$t('common.Select_work_location')" 
                                     :selectLabel="$t('common.Add_work_location')" 
                                     :selectedLabel="$t('Added')"
                                     :deselectLabel="$t('common.Remove_work_location')"
                                     track-by="tag" 
                                     label="label"
                                     @search-change="asyncFindWorkLocation">
                          <span slot="noResult">{{ $t('common.No_work_locations_found') }}</span>
                          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                        </multiselect>
                        <multiselect v-if="screenContentDetails.birthday_type.tag === 'department'"
                                     class="data_table"
                                     v-model="screenContentDetails.birthdays_from_department" 
                                     :options="screenContentValues" 
                                     :multiple="false"                
                                     :placeholder="$t('common.Select_department')"
                                     :selectLabel="$t('common.Add_department')" 
                                     :selectedLabel="$t('Added')"
                                     :deselectLabel="$t('common.Remove_department')"
                                     track-by="department_id" 
                                     label="department_name"
                                     @search-change="asyncFindDepartment">
                          <span slot="noResult">{{ $t('common.no_departments_found') }}</span>
                          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                        </multiselect>
                        <multiselect v-if="screenContentDetails.birthday_type.tag === 'team'"
                                     class="data_table"
                                     v-model="screenContentDetails.birthdays_from_team" 
                                     :options="screenContentValues" 
                                     :multiple="false"                
                                     :placeholder="$t('common.Select_team')" 
                                     :selectLabel="$t('common.Add_team')" 
                                     :selectedLabel="$t('Added')"
                                     :deselectLabel="$t('common.Remove_team')"
                                     track-by="team_id" 
                                     label="team_name"
                                     @search-change="asyncFindTeam">
                          <span slot="noResult">{{ $t('common.no_teams_found') }}</span>
                          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                        </multiselect>                                                
                      </CCol>
                    </CRow>                    


                    <CRow v-if="screenContentDetails.type.type_code">
                      <CCol cols="12" lg="12" class="pt-0 pb-0">
                        <CButton color="primary" @click="updateScreenContent(screenContentDetails.signage_content_id_external, screenContentDetails);">
                          <i class="fas fa-check mr-1"/>
                          <span>{{ $t('common.Update_content') }}</span>
                        </CButton>
                      </CCol>
                    </CRow>                     
                  </div>
                  <div v-else>
                    <CRow>
                      <CCol cols="12" lg="12" class="pt-0 pb-0">
                        <span>{{$t('signage.Select_to_view_details')}}</span>
                      </CCol>
                    </CRow>
                  </div>               
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol> 
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import QuickEdit from 'vue-quick-edit';
import ImageUploader from 'vue-image-upload-resize';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import loadingSpinner from '@/components/common/loadingSpinner.vue'
import noPermission from '@/components/common/noPermission.vue';

export default {
  name: 'Group',
  components: {
    Multiselect,
    QuickEdit,
    ImageUploader,
    loadingSpinner,
    noPermission
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      apiBaseUrl: null,
      clientToken: null,
      screenIdExternal: null,
      screen: {},
      screenContent: [],
      screenDataLoaded: false,
      screenContentLoaded: false,
      screenContentDetails: { signage_content_id_external: null },
      screenContentTypes: [],
      screenContentValues: [],
      birthdayTypes: [
        { tag: 'work_location', label: 'Work location'},
        { tag: 'department', label: 'Department'},
        { tag: 'team', label: 'Team'}
      ],
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ],
      },
      maxImageFilesize: 10485760,
      isPaginated: false,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 15,
      maxCount: 750,
      remainingCount: 750             
    }
  },
  methods: {
    asyncFindSwGroup(query) {
      let searchTerm = query;
      this.screenContentValues = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/connect/flows/' + searchTerm)
        .then(res => {      
          this.screenContentValues = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindWorkLocation(query) {
      let searchTerm = query;
      this.screenContentValues = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/workLocation/' + searchTerm)
        .then(res => {      
          this.screenContentValues = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },    
    asyncFindDepartment(query) {
      let searchTerm = query;
      this.screenContentValues = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/departments/' + searchTerm)
        .then(res => {      
          this.screenContentValues = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindTeam(query) {
      let searchTerm = query;
      this.screenContentValues = [];

      if(searchTerm.length >= 2) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/teams/' + searchTerm)
        .then(res => {      
          this.screenContentValues = res.data.data;    
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },       
    getScreenContentTypes() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/signage/content/types')
      .then(res => {      
        this.screenContentTypes = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    setContentType() {
      // Delete the content specific elements from the object
      delete this.screenContentDetails['sw_group'];
      delete this.screenContentDetails['sw_group_post_amount'];
      delete this.screenContentDetails['new_content_image'];
      delete this.screenContentDetails['info_slide_text'];  

      // Add the new_content_image variable to the screenContentDetails
      if(this.screenContentDetails.type.type_code === 'single_image') {
        this.screenContentDetails.new_content_image = null;
      }
      // Add the sw_group and sw_group_post_amount variables to the screenContentDetails
      if(this.screenContentDetails.type.type_code === 'sw_group_posts') {
        this.screenContentDetails.sw_group = null;
        this.screenContentDetails.sw_group_post_amount = 6;
      }      
      // Add the info_slide_text variable to the screenContentDetails
      if(this.screenContentDetails.type.type_code === 'info_slide') {
        this.screenContentDetails.info_slide_text = '';
        this.screenContentDetails.new_content_image = null;
      }
    },     
    updateScreen(data) {
      let params = {}
      params = this.screen;
      (params.is_active === true) ? params.active = 'Y' : params.active = 'N';
      if(params.screen_description === '') params.screen_description = null;

      let screen_title = params.screen_title;

      if(screen_title) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/connect/signage/screen/' + this.screenIdExternal, params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('signage.Screen_updated'), type: 'is-success', duration: 2000 });
          // Get the screen details
          this.getScreenDetails();
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }            
    },
    getScreenDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/signage/screen/' + this.screenIdExternal)
      .then(res => {
        this.screen = res.data.data;
        this.screenDataLoaded = true;       
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getScreenContent() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/signage/content/screen/' + this.screenIdExternal)
      .then(res => {
        this.screenContent = res.data.data;
        this.screenContentLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },     
    addScreenContent() {
      axios.post(process.env.VUE_APP_API_URL + '/v1/connect/signage/content/screen/' + this.screenIdExternal)
      .then(res => {
        // Show a success noitce
        this.$buefy.toast.open({ message: this.$t('signage.Content_added'), type: 'is-success', duration: 2000 });
        // Get the screen content
        this.getScreenContent();
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },
    removeScreenContent(signageContentIdExternal) {            
      axios.delete(process.env.VUE_APP_API_URL + '/v1/connect/signage/content/' + signageContentIdExternal)
      .then(res => {
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('signage.Content_removed'), type: 'is-success', duration: 2000 });
        // Reset the right side of screen
        this.screenContentDetails.signage_content_id_external = null;
        // Get the screen content
        this.getScreenContent();
      })
      .catch(err => {
        // console.error(err);
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      });
    },
    updateScreenContent(signageContentIdExternal, data) {
      let params = {};
      params = data;
      (params.is_active === true) ? params.active = 'Y' : params.active = 'N';

      axios.put(process.env.VUE_APP_API_URL + '/v1/connect/signage/content/' + signageContentIdExternal, params)
      .then(res => {
        // Handle the file upload if the content contains files
        if(params.new_content_image) {
          // Create new formdata              
          const formData = new FormData();
          // Append the new content images to the formdata          
          formData.append("image", params.new_content_image);          
          // Upload the files
          axios.post('v1/common/upload/image/signage/' + signageContentIdExternal, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          .then(res => {
            // Show a success notice
            this.$buefy.toast.open({ message: this.$t('signage.Content_updated'), type: 'is-success', duration: 2000 });        
            // Get the screen content details
            this.getScreenContentDetails(signageContentIdExternal);
          })
          .catch(err => {
            console.error(err); 
          });
        } else {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('signage.Content_updated'), type: 'is-success', duration: 2000 });        
          // Get the screen content details
          this.getScreenContentDetails(signageContentIdExternal);
        }
      })
      .catch(err => {
        // console.error(err);
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      });
    },
    getScreenContentDetails(id) {
      let signageContentIdExternal = id;

      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/signage/content/' + signageContentIdExternal)
      .then(res => {
        // Update the screenContentDetails
        this.screenContentDetails = res.data.data

        let updatedContent = res.data.data;
        // Check if table refs are available
        if(this.$refs !== undefined && this.$refs.contentTable !== undefined ) {
          // Get the content index
          let contentIndex = this.$refs.contentTable.data.findIndex(x => x.signage_content_id_external == updatedContent.signage_content_id_external);
          // Update the is active value for the row        
          this.$refs.contentTable.data[contentIndex].is_active = updatedContent.is_active;
          this.$refs.contentTable.data[contentIndex].type = updatedContent.type;
          this.$refs.contentTable.data[contentIndex].signage_content_type_id = updatedContent.signage_content_type_id;
          this.$refs.contentTable.data[contentIndex].signage_content_variable = updatedContent.signage_content_variable;
          this.$refs.contentTable.data[contentIndex].signage_content_variable_2 = updatedContent.signage_content_variable_2;
          this.$refs.contentTable.data[contentIndex].variable_amount = updatedContent.variable_amount;

          if(updatedContent.type_code === 'sw_group_posts') {
            this.$refs.contentTable.data[contentIndex].sw_group = updatedContent.sw_group;
            this.$refs.contentTable.data[contentIndex].sw_group_post_amount = updatedContent.sw_group_post_amount;  
          } else if(updatedContent.type_code === 'info_slide') {
            this.$refs.contentTable.data[contentIndex].info_slide_text = updatedContent.info_slide_text;
          } else if(updatedContent.type_code === 'birthdays') {
            this.$refs.contentTable.data[contentIndex].birtday_type = updatedContent.birtday_type;
            this.$refs.contentTable.data[contentIndex].birtday_message = updatedContent.signage_content_variable_3;
          }
        }      
      })
      .catch(err => {
        console.error(err); 
      });
    },
    pickContentImage() {
      document.getElementById("imageUpload").click()
    },
    onContentImageChange(e) {
      let file = e.target.files[0];
      
      let filesize = file.size;
      // Check the filesize exceeds the maximum
      if(filesize > this.maxImageFilesize) {
        // If so, notify the user
        this.$buefy.toast.open({ message: files[i].name + ' (' + this.formatFileSize(filesize) + ') ' + this.$t('upload.exceeds_maximum_filesize_of') + ' ' + this.formatFileSize(this.maxImageFilesize) + '.', type: 'is-danger', duration: 2000 });
      } else {
        // If not, set the file as the new_content_image array of the screenContentDetails
        this.screenContentDetails.new_content_image = file;      
        // Create an image URL for the file
        this.screenContentDetails.new_content_image.image_url = URL.createObjectURL(file);        
      }
      // Force update to rerender view
      this.$forceUpdate();
    },
    removeContentImage(index) {
      this.screenContentDetails.new_content_image = null;
      // Force update to rerender view
      this.$forceUpdate();
    },
    showSignageOverview() {
      this.$router.push({path: '/connect/signage'});
    },     
    countdown () {
      if(this.screenContentDetails.type_code === 'info_slide') this.remainingCount = this.maxCount - this.screenContentDetails.info_slide_text.length;
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    } 
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    this.screenIdExternal = this.$route.params.id;

    this.getPlatformPermissions();
    this.getScreenDetails();
    this.getScreenContent();
    this.getScreenContentTypes();
  }
}
</script>